import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import type { SignUp } from '../../../molecule/Auth/Login/Login';
import { Button } from '../../Button/Button';

import type { IFacebookLogin } from './FacebookLogin.d';
import style from './style.scss';

/**
 * Facebook Login
 *
 * Facebook Login component allowing to show social login.
 **/
@Component({})
export class FacebookLogin extends BaseComponent<IFacebookLogin, unknown> {
  /**
   * Determines title facebook button
   */
  @Prop({ type: Object, required: true })
  facebookLoginName: I18nMessage;

  /**
   * Determines button style
   */
  @Prop({ type: String, required: true })
  styleType: SignUp;

  render() {
    return (
      <div
        class={{
          [style.facebookWrapper]: true,
          [style.drawer]: this.styleType === 'drawer',
        }}>
        <Button styleType='ghost' type='link' class={{ [style.defaultBtn]: this.styleType === 'default' }}>
          <span>{this.getTranslation(this.facebookLoginName)}</span>
        </Button>
      </div>
    );
  }
}
