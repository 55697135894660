import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { Form } from 'theme/components/Form/Form';
import { Input } from 'theme/components/Form/Input';
import { IFormValidation } from 'theme/components/Form/types';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import { Button } from '../../../../atom/Button/Button';

import type { IFormEmail } from './Form.d';
import style from './style.scss';

/**
 * Forgot Password Form
 *
 * Forgot Password Form component allowing to show form input.
 **/
@Component({})
@Form()
export class FormEmail extends BaseComponent<IFormEmail, IFormValidation> {
  /**
   * Determines state prop
   */
  @Prop({ type: Object, required: false })
  state: Record<string, any>;

  /**
   * Determines form submit
   */
  @Prop({ type: Function, default: () => undefined })
  sendEmail?: (ev: Event) => void;

  /**
   * Determines login modal
   */
  @Prop({ type: Function, default: () => undefined })
  switchElem?: (ev: Event) => void;

  /**
   * Set title for email
   */
  @Prop({ type: Object, default: undefined })
  forgotPassEmailAddress?: I18nMessage;

  /**
   * Set title for label
   */
  @Prop({ type: Object, default: undefined })
  forgotPassLabel?: I18nMessage;

  /**
   * Set title for divider
   */
  @Prop({ type: Object, default: undefined })
  forgotPassOr?: I18nMessage;

  /**
   * Set title for forgot password return
   */
  @Prop({ type: Object, default: undefined })
  forgotPassReturnToLogin?: I18nMessage;

  /**
   * Set message form
   */
  @Prop({ type: Object, default: undefined })
  forgotPassMessage?: I18nMessage;

  /**
   * Set cancel title for forgot password return
   */
  @Prop({ type: Object, default: undefined })
  cancelLabel?: I18nMessage;

  /**
   * A value which will render a different style
   */
  @Prop({ type: String, required: true })
  styleType: string;

  render() {
    const isInvalid = !this.extended.zValidation.valid;

    return (
      <form
        class={{
          [style.formDrawer]: this.styleType === 'drawer',
        }}
        onsubmit={this.sendEmail}
        key='form'>
        {this.styleType === 'drawer' ? <p>{this.getTranslation(this.forgotPassMessage)}</p> : null}
        <Input
          state={this.state}
          valueKeeper='email'
          name='email'
          type='email'
          validateOn='input'
          required={true}
          minLength={5}
          dataTestId='forgotPassEmailAddress'
          pattern='email'>
          <span slot='label'>{this.getTranslation(this.forgotPassEmailAddress)}</span>
        </Input>
        <div class={style.buttonsSet} key='buttons'>
          <Button
            styleType='primary'
            type='submit'
            name='send'
            class={style.buttonContainer}
            disabled={isInvalid}
            dataTestId='resetPassword'
            key='submit'>
            {this.getTranslation(this.forgotPassLabel)}
          </Button>
          <p key='button-container'>
            <span key='forgot-password-or'>{this.getTranslation(this.forgotPassOr)}</span>
            <a
              href='#login'
              title={this.getTranslation(this.forgotPassReturnToLogin)}
              onClick={this.switchElem}
              class={style.returnLogToLogin}
              data-testid='returnLogToLogin'
              key='return-to-login'>
              {this.styleType === 'drawer' ? (
                <span>{this.getTranslation(this.cancelLabel)}</span>
              ) : this.styleType === 'default' ? (
                <span>{this.getTranslation(this.forgotPassReturnToLogin)}</span>
              ) : null}
            </a>
          </p>
        </div>
      </form>
    );
  }
}
