import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { Form } from 'theme/components/Form/Form';
import { Input } from 'theme/components/Form/Input';
import { Checkbox } from 'theme/components/Form/Checkbox';
import type { IFormValidation } from 'theme/components/Form/types';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import { AnalyticsConversionApiStore } from 'theme/stores/analyticsConversionApi/analyticsConversionApi';
import { CustomFormsStore } from 'theme/stores/customForms/customForms';
import { FormFieldsLocation } from '@zento/modules/organism/CustomForms/FormFieldsLocation';

import { Button } from '../../../atom/Button/Button';
import { SocialLogin } from '../../../atom/SocialLogin/SocialLogin';
import { NameForm } from '../../NameForm/NameForm';
import { TermsAndConditions } from '../../../atom/TermsAndConditions/TermsAndConditions';
import { CmsBlock } from '../../CmsBlock/CmsBlock';
import style from '../style.scss';

import type { IRegister } from './Register.d';

// Defines the different style types for login
type SignUp = 'default' | 'drawer';

/**
 * Register
 *
 * Register component allowing to show form inputs.
 **/
@Component({})
@Form()
export class Register extends BaseComponent<IRegister, any & IFormValidation> {
  private static T = {
    registerEmailAddress: 'register_email_address',
    registerPassword: 'register_password',
    registerlabel: 'register_label',
    registerSuccess: 'register_success',
    registerNewsletterLabel: 'register_newsletter_label',
    registerButtonLabel: 'register_button_label',
    registerUserButton: 'register_user_button',
    socialLoginLabel: 'social_login_label',
    socialLoginLabelOr: 'social_login_label_or',
    googleLogin: 'google_login',
    facebookLogin: 'facebook_login',
    reviewTerms: 'terms_and_conditions',
    reviewPrivacyPolicy: 'review_privacy_policy',
    reviewAgreedAnd: 'review_agreed_and',
    signInLabel: 'sign_in_label',
    registerAccount: 'register_account',
    registerAccountError: 'register_account_error',
    requiredMessage: 'required_message',
  };

  /**
   * A value which will render a different style
   */
  @Prop({ type: String, required: true })
  styleType: SignUp;

  private storeConfig = new StoreConfig();
  private analyticsConversionApiStore = new AnalyticsConversionApiStore();
  protected formStore = new CustomFormsStore();

  data() {
    return {
      customer: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
      },
      conditions: false,
      toggleInput: false,
    };
  }

  /**
   *  Show register form
   **/
  register(ev: Event) {
    ev.preventDefault();

    this.callRegister();

    document.body.style.overflow = 'visible';
  }

  /**
   *  Dispatch register informations
   **/
  callRegister() {
    this.broadcast('notification-progress-start', this.getTranslation({ id: Register.T.registerAccount }));
    this.$store
      .dispatch('user/register', {
        email: this.$data.customer.email,
        password: this.$data.customer.password,
        firstname: this.$data.customer.firstname,
        lastname: this.$data.customer.lastname,
      })
      .then((res) => {
        this.broadcast('notification-progress-stop');

        if (res.code !== 200) {
          this.onFailure(res);
        } else {
          if (this.storeConfig.storeConfigurations.analyticsDataboxGeneralActive) {
            const today = new Date();

            this.analyticsConversionApiStore.send({
              eventName: 'Register',
              userData: {
                id: this.$data.customer.email,
                created_at: today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear(),
              },
            });
          }

          this.$store.dispatch('user/login', {
            username: this.$data.customer.email,
            password: this.$data.customer.password,
          });

          if (this.customFieldData) {
            const preSignedPost = JSON.parse(this.formStore.accountLocation.preSignedPost);

            this.formStore.fieldsLocation = { ID: res.result.id };

            if (
              this.formStore.formDataInputs.length &&
              preSignedPost !== null &&
              Object.keys(preSignedPost).length &&
              'action' in preSignedPost
            ) {
              this.formStore.formImageUploadFile(preSignedPost, this.formStore.formDataInputs);
            }

            if (Object.keys(this.formStore.fieldsLocation).length) {
              this.formStore.send(
                JSON.stringify({ ...this.formStore.fieldsLocation }),
                this.formStore.accountLocation.title,
                parseInt(this.formStore.accountLocation.formId),
                false,
              );
            }
          }

          this.onSuccess();
          this.close();
        }
      })
      .catch((err) => {
        this.onFailure({ result: err ?? this.getTranslation({ id: Register.T.registerAccountError }) });
        this.broadcast('notification-progress-stop');
      });
  }

  /**
   * Close my account drawer/modal
   */
  close() {
    this.broadcast('modal-hide', 'modal-signup');
    this.closeDrawer();
  }

  /**
   * Close my account drawer
   */
  private closeDrawer() {
    this.$store.commit('myAccount/setDrawerActive', false);
    this.$store.commit('ui/setOverlay', false);
    document.body.style.overflow = 'visible';
  }

  /**
   *  Show success notification
   **/
  onSuccess() {
    this.$store.dispatch('notification/spawnNotification', {
      type: 'success',
      message: this.getTranslation({ id: Register.T.registerSuccess }),
    });

    this.broadcast('analytics/register-success');
  }

  /**
   *  Show error notification
   **/
  onFailure(result: Record<string, any>) {
    const message = Array.isArray(result.result)
      ? result.result[0]?.dataPath + ' should match pattern. Please try again.'
      : result.result;

    this.$store.dispatch('notification/spawnNotification', {
      type: 'error',
      message: this.getTranslation({ id: message }),
    });
  }

  toggleInputType() {
    this.$data.toggleInput = !this.$data.toggleInput;
  }

  switchElement(ev: Event) {
    ev.preventDefault();
    this.$store.commit('ui/setAuthElem', 'login');

    if (this.styleType === 'default') {
      this.broadcast('modal-show', 'modal-signup');
    }
  }

  /**
   * Determines checkout form
   */
  private get customFieldData() {
    return 'location' in this.formStore.accountLocation;
  }

  /**
   * Fetches register form data
   */
  private async fetchFormData() {
    return await this.formStore.fetchCustomFormLocation('customer_registration');
  }

  async mounted() {
    if (!Object.keys(this.formStore.accountLocation).length) {
      this.fetchFormData();
    }
  }

  beforeMount() {
    this.toggleInputType = this.toggleInputType.bind(this);
    this.switchElement = this.switchElement.bind(this);
  }

  render() {
    const isInvalid = !this.extended.zValidation.valid;
    const config = this.extended.$config.zento.theme;

    return (
      <div
        class={{
          [style.contentBox]: true,
          [style.drawer]: this.styleType === 'drawer',
        }}>
        {this.styleType === 'default' ? (
          <header class={style.modalHeader} key='modal-header'>
            <h2 key='title'>
              <span>{this.getTranslation({ id: Register.T.registerlabel })}</span>
            </h2>
          </header>
        ) : null}

        <div class={style.formWrapper} key='form-wrapper'>
          <div class={style.formContent} key='form-content'>
            {config.enableSocialLogIn ? (
              <SocialLogin
                styleType={this.styleType}
                key='social-login'
                socialLoginTitle={{ id: Register.T.socialLoginLabel }}
                socialLoginLabelOr={{ id: Register.T.socialLoginLabelOr }}
                facebookLoginBtn={{ id: Register.T.facebookLogin }}
                googleLoginBtn={{ id: Register.T.googleLogin }}
              />
            ) : null}

            <form onsubmit={this.register} key='form'>
              {this.customFieldData && this.formStore.accountLocation.beforeAccount.length ? (
                <FormFieldsLocation
                  customForm={this.formStore.accountLocation}
                  formFields={this.formStore.accountLocation.beforeAccount}
                  key='register-fields-before-account-details'
                />
              ) : null}

              {/* TODO: myAccountDetails should be removed after all queries get the same data info */}
              <NameForm state={this.$data.customer} myAccountDetails={true} key='name-form' />

              <div key='form-elements'>
                <Input
                  state={this.$data.customer}
                  valueKeeper='email'
                  name='email'
                  type='email'
                  validateOn='input'
                  required={true}
                  minLength={5}
                  pattern='email'
                  dataTestId='registerEmail'>
                  <span slot='label'>{this.getTranslation({ id: Register.T.registerEmailAddress })}</span>
                </Input>
                <Input
                  state={this.$data.customer}
                  valueKeeper='password'
                  name='password'
                  type={!this.$data.toggleInput ? 'password' : 'text'}
                  validateOn='input'
                  required={true}
                  minLength={8}
                  pattern='password'
                  class={style.passwordInput}
                  dataTestId='registerPassword'>
                  <span slot='label' key='input-label'>
                    {this.getTranslation({ id: Register.T.registerPassword })}
                  </span>
                  <span
                    onClick={this.toggleInputType}
                    slot='inside'
                    class={{
                      [style.toggleInput]: true,
                      [style.showPassWord]: !this.$data.toggleInput,
                      [style.hidePassWord]: this.$data.toggleInput,
                    }}
                    key='toggle-input-type'
                  />
                </Input>
              </div>

              {this.customFieldData && this.formStore.accountLocation.afterAccount.length ? (
                <FormFieldsLocation
                  customForm={this.formStore.accountLocation}
                  formFields={this.formStore.accountLocation.afterAccount}
                  key='register-fields-after-account'
                />
              ) : null}

              <div class={style.inputCheckbox}>
                <Checkbox
                  state={this.$data}
                  valueKeeper='conditions'
                  name='acceptConditions'
                  id='terms'
                  type='checkbox'
                  validateOn='change'
                  required={true}
                  checked={true}
                  label={this.getTranslation({ id: Register.T.registerButtonLabel })}
                  dataTestId='registerCheckbox'
                />

                <TermsAndConditions
                  key='agree-terms'
                  reviewTerms={{ id: Register.T.reviewTerms }}
                  reviewPrivacyPolicy={{ id: Register.T.reviewPrivacyPolicy }}
                  reviewAgreed={{ id: Register.T.reviewAgreedAnd }}
                />

                {config.termsAndConditionsAdditional ? (
                  <CmsBlock
                    identifier={config.termsAndConditionsAdditional}
                    class={style.termsAndConditions}
                    key='terms-and-conditions'
                  />
                ) : null}
              </div>

              <div class={style.requiredMessage}>{this.getTranslation({ id: Register.T.requiredMessage })}</div>

              <div class={style.buttonsSet} key='buttons'>
                <Button
                  styleType='primary'
                  type='submit'
                  name='send'
                  title={{ id: Register.T.signInLabel }}
                  data-testId='registerSubmit'
                  key='login'
                  disabled={isInvalid}
                  class={style.registerBtn}
                  dataTestId='registerSubmit'>
                  <span>{this.getTranslation({ id: Register.T.registerlabel })}</span>
                </Button>
                <p class={style.buttonContainer} key='button-container'>
                  <span>{this.getTranslation({ id: Register.T.registerNewsletterLabel })}</span>
                  <a
                    href='#login'
                    title={this.getTranslation({ id: Register.T.registerlabel })}
                    name='noaccount'
                    onClick={this.switchElement}
                    class={style.noAccount}
                    key='register'
                    data-testid='goToLogin'>
                    <span>{this.getTranslation({ id: Register.T.registerUserButton })}</span>
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
