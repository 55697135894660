import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import type { SignUp } from '../../molecule/Auth/Login/Login';

import { GoogleLogin } from './GoogleLogin/GoogleLogin';
import { FacebookLogin } from './FacebookLogin/FacebookLogin';
import type { ISocialLogin } from './SocialLogin.d';
import style from './style.scss';

/**
 * Social Login
 *
 * Social Login component allowing to show social login buttons.
 **/
@Component({})
export class SocialLogin extends BaseComponent<ISocialLogin, unknown> {
  /**
   * Determines title facebook button
   */
  @Prop({ type: Object, required: true })
  socialLoginTitle: I18nMessage;

  /**
   * Determines title divider text
   */
  @Prop({ type: Object, required: true })
  socialLoginLabelOr: I18nMessage;

  /**
   * Determines title facebook button
   */
  @Prop({ type: Object, required: true })
  facebookLoginBtn: I18nMessage;

  /**
   * Determines title google button
   */
  @Prop({ type: Object, required: true })
  googleLoginBtn: I18nMessage;

  /**
   * Determines button style
   */
  @Prop({ type: String, required: true })
  styleType: SignUp;

  render() {
    return (
      <div class={style.socialAccount}>
        <h3 key='social-login-title'>{this.getTranslation(this.socialLoginTitle)}</h3>
        <div class={style.socialWrapper} key='social-login'>
          <GoogleLogin styleType={this.styleType} key='google-login' googleLoginName={this.googleLoginBtn} />
          <FacebookLogin styleType={this.styleType} key='facebook-login' facebookLoginName={this.facebookLoginBtn} />
        </div>
        <span class={style.orDivider}>
          <span key='social-text'>{this.getTranslation(this.socialLoginLabelOr)}</span>
        </span>
      </div>
    );
  }
}
