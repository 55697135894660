import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { IVSFStoreUI, KEY as UIKey } from 'theme/@types/vsf/stores/ui';
import LoginMixin from '@vue-storefront/core/compatibility/components/blocks/Auth/Login';
import { Form } from 'theme/components/Form/Form';
import { Input } from 'theme/components/Form/Input';
import type { IFormValidation } from 'theme/components/Form/types';

import { Button } from '../../../atom/Button/Button';
import { SocialLogin } from '../../../atom/SocialLogin/SocialLogin';
import style from '../style.scss';

import type { ILogin } from './Login.d';

const uiStore = namespace<IVSFStoreUI>(UIKey);

// Defines the different style types for login
export type SignUp = 'default' | 'drawer';

interface ILoginVSF {
  callLogin(): void;
}

/**
 * Login
 *
 * Login component allowing to show form inputs.
 **/
@Component({
  mixins: [LoginMixin],
})
@Form()
export class Login extends BaseComponent<ILogin, ILoginVSF & IFormValidation> {
  private static T = {
    loginLabel: 'login_label',
    loginEmailAddress: 'login_email_address',
    loginPassword: 'login_password',
    loginSuccess: 'login_success',
    loginErrorResetPassword: 'login_reset_password',
    loginLoggedIn: 'login_logged_in',
    loginButtonLabel: 'login_button_label',
    loginRegisterButton: 'login_user_button',
    loginForgotPassword: 'login_forgot_password',
    registerLabel: 'register_label',
    socialLoginLabel: 'social_login_label',
    socialLoginLabelOr: 'social_login_label_or',
    googleLogin: 'google_login',
    facebookLogin: 'facebook_login',
    inputShowPassword: 'input_show_password',
    inputHidePassword: 'input_hide_password',
  };

  /**
   * A value which will render a different style
   */
  @Prop({ type: String, required: true })
  styleType: SignUp;

  @uiStore.Mutation('setAuthElem')
  setAuthElem: IVSFStoreUI['state']['authElem'];

  data() {
    return {
      email: '',
      password: '',
      hasRedirect: !!localStorage.getItem('redirect'),
      toggleInput: false,
    };
  }

  beforeMount() {
    this.toggleInputType = this.toggleInputType.bind(this);
    this.switchElement = this.switchElement.bind(this);
  }

  /**
   *  Close modal
   **/
  close(e) {
    if (e) {
      localStorage.removeItem('redirect');
    }

    this.broadcast('modal-hide', 'modal-signup');
    this.closeDrawer();
  }

  /**
   * Close my account drawer
   */
  private closeDrawer() {
    this.$store.commit('myAccount/setDrawerActive', false);
    this.$store.commit('ui/setOverlay', false);
    document.body.style.overflow = 'visible';
  }

  /**
   *  Show login form
   **/
  login(ev: Event) {
    ev.preventDefault();
    this.extended.callLogin();
    document.body.style.overflow = 'visible';
  }

  /**
   *  Show forgot password form
   **/
  remindPassword(ev: Event) {
    ev.preventDefault();

    if (!(typeof navigator !== 'undefined' && navigator.onLine)) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.getTranslation({ id: Login.T.loginErrorResetPassword }),
      });
    } else {
      this.$store.commit('ui/setAuthElem', 'forgot-pass');
    }
  }

  /**
   *  Show success notification
   **/
  onSuccess() {
    this.$store.dispatch('notification/spawnNotification', {
      type: 'success',
      message: this.getTranslation({ id: Login.T.loginSuccess }),
    });
  }

  /**
   *  Show error notification
   **/
  onFailure(result) {
    this.$store.dispatch('notification/spawnNotification', {
      type: 'error',
      message: this.$t(result.result),
    });
  }

  toggleInputType() {
    this.$data.toggleInput = !this.$data.toggleInput;
  }

  switchElement(ev: Event) {
    ev.preventDefault();
    this.$store.commit('ui/setAuthElem', 'register');

    if (this.styleType === 'default') {
      this.broadcast('modal-show', 'modal-signup');
    }
  }

  render() {
    const isInvalid = !this.extended.zValidation.valid;

    return (
      <div
        class={{
          [style.contentBox]: true,
          [style.drawer]: this.styleType === 'drawer',
        }}>
        {this.styleType === 'default' ? (
          <header class={style.modalHeader} key='header'>
            <h2>{this.getTranslation({ id: Login.T.loginLabel })}</h2>
          </header>
        ) : null}

        {this.$data.hasRedirect ? (
          <div class={style.redirectError} key='redirect'>
            {this.getTranslation({ id: Login.T.loginLoggedIn })}
          </div>
        ) : null}

        <div class={style.formWrapper} key='form-wrapper'>
          <div class={style.formContent}>
            {this.extended.$config.zento.theme.enableSocialLogIn ? (
              <SocialLogin
                styleType={this.styleType}
                key='social-login'
                socialLoginTitle={{ id: Login.T.socialLoginLabel }}
                socialLoginLabelOr={{ id: Login.T.socialLoginLabelOr }}
                facebookLoginBtn={{ id: Login.T.facebookLogin }}
                googleLoginBtn={{ id: Login.T.googleLogin }}
              />
            ) : null}
            <form onSubmit={this.login} key='form'>
              <div key='form-elements'>
                <Input
                  state={this.$data}
                  valueKeeper='email'
                  name='email'
                  type='email'
                  validateOn='input'
                  required={true}
                  minLength={5}
                  pattern='email'
                  dataTestId='logInEmail'>
                  <span slot='label'>{this.getTranslation({ id: Login.T.loginEmailAddress })}</span>
                </Input>
                <Input
                  state={this.$data}
                  valueKeeper='password'
                  name='password'
                  type={!this.$data.toggleInput ? 'password' : 'text'}
                  validateOn='input'
                  required={true}
                  minLength={2}
                  class={style.passwordInput}
                  dataTestId='logInPassword'>
                  <span slot='label' key='input-label'>
                    {this.getTranslation({ id: Login.T.loginPassword })}
                  </span>
                  <span
                    onClick={this.toggleInputType}
                    slot='inside'
                    class={{
                      [style.toggleInput]: true,
                      [style.showPassWord]: !this.$data.toggleInput,
                      [style.hidePassWord]: this.$data.toggleInput,
                    }}
                    key='toggle-input-type'
                  />
                </Input>
              </div>
              <div class={style.buttonsSet} key='form-btns'>
                <a
                  href='#forgotpassword'
                  class={style.forgotBtn}
                  onClick={this.remindPassword}
                  key='forgot-password'
                  data-testid='logInForgotPass'>
                  {this.getTranslation({ id: Login.T.loginForgotPassword })}
                </a>
                <Button
                  type='submit'
                  styleType='primary'
                  name='send'
                  title={{ id: 'LogIn' }}
                  class={style.buttonContainer}
                  data-testId='loginSubmit'
                  disabled={isInvalid}
                  key='login'
                  dataTestId='logInSubmit'>
                  <span>{this.getTranslation({ id: Login.T.loginLabel })}</span>
                </Button>
                <p key='form-register'>
                  <span>{this.getTranslation({ id: Login.T.loginButtonLabel })}</span>
                  <a
                    class={style.popUpRegister}
                    title={this.getTranslation({ id: Login.T.registerLabel })}
                    name='noaccount'
                    id='noaccount'
                    data-testId='goToRegister'
                    onclick={this.switchElement}
                    href='#register'
                    key='register'>
                    <span>{this.getTranslation({ id: Login.T.loginRegisterButton })}</span>
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
