import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import type { SignUp } from '../../../molecule/Auth/Login/Login';
import { Button } from '../../Button/Button';

import { IGoogleLogin } from './GoogleLogin.d';
import style from './style.scss';

/**
 * Google Login
 *
 * Google Login component allowing to show social login.
 **/
@Component({})
export class GoogleLogin extends BaseComponent<IGoogleLogin, unknown> {
  /**
   * Determines title google button
   */
  @Prop({ type: Object, required: true })
  googleLoginName: I18nMessage;

  /**
   * Determines button style
   */
  @Prop({ type: String, required: true })
  styleType: SignUp;

  render() {
    return (
      <div
        class={{
          [style.googleConnectLogin]: true,
          [style.drawer]: this.styleType === 'drawer',
        }}>
        <Button styleType='ghost' type='link' class={{ [style.defaultBtn]: this.styleType === 'default' }}>
          <span>{this.getTranslation(this.googleLoginName)}</span>
        </Button>
      </div>
    );
  }
}
