import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import { Button } from '../../../../atom/Button/Button';

import type { IFormSuccess } from './FormSuccess.d';
import style from './style.scss';

/**
 * Forgot Password Form Success
 *
 * Forgot Password Form Success component allowing to show success message.
 **/
@Component({})
export class FormSuccess extends BaseComponent<IFormSuccess, unknown> {
  /**
   * Determines login modal
   */
  @Prop({ type: Function, default: () => undefined })
  switchElem: (ev: Event) => void;

  /**
   * Set title for message
   */
  @Prop({ type: Object, required: true })
  forgotPassResetPasswordMessage: I18nMessage;

  /**
   * Set title for forgot password return
   */
  @Prop({ type: Object, required: true })
  forgotPassReturnToLogin: I18nMessage;

  render() {
    return (
      <div class={style.passwordSent} key='form-send'>
        <p key='message'>{this.getTranslation(this.forgotPassResetPasswordMessage)}</p>
        <Button styleType='primary' type='link' handler={this.switchElem} key='return'>
          {this.getTranslation(this.forgotPassReturnToLogin)}
        </Button>
      </div>
    );
  }
}
