import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { Form } from 'theme/components/Form/Form';

import style from '../style.scss';

import type { IForgotPassword } from './ForgotPassword.d';
import { FormEmail } from './Form/Form';
import { FormSuccess } from './FormSuccess/FormSuccess';

// Defines the different style types for login
type SignUp = 'default' | 'drawer';

/**
 * Forgot Password
 *
 * Forgot Password component allowing to show form input.
 **/
@Component({})
@Form()
export class ForgotPassword extends BaseComponent<IForgotPassword, unknown> {
  private static T = {
    forgotPassEmailAddress: 'login_email_address',
    forgotPassLabel: 'forgotPass_label',
    forgotPassErrorMessage: 'forgotPass_error_message',
    forgotPassResetPass: 'forgotPass_reset_pass',
    forgotPassReturnToLogin: 'forgotPass_return_to_login',
    forgotPassResetPasswordMessage: 'forgotPass_reset_password_message',
    forgotPassOr: 'forgotPass_or',
    cancelLabel: 'forgotPass_cancel_label',
    forgotPassMessage: 'forgotPass_message',
  };

  /**
   * A value which will render a different style
   */
  @Prop({ type: String, required: true })
  styleType: SignUp;

  data() {
    return {
      email: '',
      passwordSent: false,
    };
  }

  /**
   *  Close modal
   **/
  close() {
    this.broadcast('modal-hide', 'modal-signup');
  }

  /**
   *  Send email function
   **/
  sendEmail(ev: Event) {
    ev.preventDefault();

    this.broadcast('notification-progress-start', this.getTranslation({ id: ForgotPassword.T.forgotPassResetPass }));
    this.$store
      .dispatch('user/resetPassword', { email: this.$data.email })
      .then((response) => {
        this.broadcast('notification-progress-stop');
        if (response.code === 200) {
          this.$data.passwordSent = true;
        } else {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message:
              this.getTranslation({ id: response.result }) ||
              this.getTranslation({ id: ForgotPassword.T.forgotPassErrorMessage }),
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.broadcast('notification-progress-stop');
      });
  }

  /**
   *  Switch form modal
   **/
  switchElem(ev: Event) {
    ev.preventDefault();
    this.$store.commit('ui/setAuthElem', 'login');
  }

  render() {
    return (
      <div
        class={{
          [style.contentBox]: true,
          [style.drawer]: this.styleType === 'drawer',
        }}>
        <header class={style.modalHeader} key='modal-header'>
          <h2 key='title'>
            <span>{this.getTranslation({ id: ForgotPassword.T.forgotPassLabel })}</span>
          </h2>
          {this.styleType === 'drawer' ? <span class={style.closeForgotPass} onClick={this.switchElem} /> : null}
        </header>

        <div class={style.formWrapper} key='form-wrapper'>
          <div class={style.formContent} key='form-content'>
            {!this.$data.passwordSent ? (
              <FormEmail
                state={this.$data}
                sendEmail={this.sendEmail}
                switchElem={this.switchElem}
                styleType={this.styleType}
                forgotPassEmailAddress={{ id: ForgotPassword.T.forgotPassEmailAddress }}
                forgotPassLabel={{ id: ForgotPassword.T.forgotPassLabel }}
                forgotPassOr={{ id: ForgotPassword.T.forgotPassOr }}
                forgotPassReturnToLogin={{ id: ForgotPassword.T.forgotPassReturnToLogin }}
                forgotPassMessage={{ id: ForgotPassword.T.forgotPassMessage }}
                cancelLabel={{ id: ForgotPassword.T.cancelLabel }}
              />
            ) : this.$data.passwordSent ? (
              <FormSuccess
                switchElem={this.switchElem}
                forgotPassResetPasswordMessage={{ id: ForgotPassword.T.forgotPassResetPasswordMessage }}
                forgotPassReturnToLogin={{ id: ForgotPassword.T.forgotPassReturnToLogin }}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
